import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BusyOverlay = ({ text, icon = 'spinner' }) => (
	<div className="busy-overlay">
		{icon && <FontAwesomeIcon icon={icon} spin size="lg" />}
		{text && <div>{text}</div>}
	</div>
);

export default BusyOverlay;
