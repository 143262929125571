import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../redux/actions';
import API from '../../lib/api-store';
import sdclogo from '../../static/images/SDC-WordMark.svg';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import SupportDropdown from './User/SupportDropdown';
import CircleText from '../General/CircleText';

class ProductBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			supportDropdownOpen: false
		};
	}

	handleLogout = (e) => {
		e.preventDefault();
		const { handleLogout } = this.props;
		handleLogout();
	};

	toggleAcountDropdown = (e) => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};

	toggleSupportDropdown = (e) => {
		this.setState({ supportDropdownOpen: !this.state.supportDropdownOpen });
	};

	redirectToProjects = (e) => {
		e.stopPropagation();
		this.props.history.push(`/projects`);
	};

	handleLogout = () => {
		const { logoutAction } = this.props;
		API.quit();
		logoutAction();
		this.props.history.push('/login');
	};

	render() {
		const { user } = this.props;
		const { dropdownOpen } = this.state;
		const myaccountDropdown = user && (
			<Dropdown className="nav" isOpen={dropdownOpen} inNavbar={true} toggle={this.toggleAcountDropdown}>
				<DropdownToggle nav>
					<CircleText text={(user.firstName[0] + user.lastName[0]).toUpperCase()} className="ml-2 mr-2" />
				</DropdownToggle>
				<DropdownMenu right>
					<DropdownItem onClick={() => this.props.history.push('/myaccount')}>My Profile</DropdownItem>
					{user &&
					user.companyData.tier_status > 4 &&
					user.admin && (
						<DropdownItem onClick={() => this.props.history.push('/qriadmin')}>QRI Admin</DropdownItem>
					)}
					{/* {user &&
					user.admin && <DropdownItem onClick={() => this.props.history.push('/admin')}>Admin</DropdownItem>} */}
					<DropdownItem onClick={this.handleLogout}>Logout</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);

		const navigation = (
			<Fragment>
				<div className="nav">
					<NavLink className="nav-text" exact to="/">
						Home
					</NavLink>
				</div>
				<div className="nav">
					<NavLink className="nav-text" exact to="/projects">
						Workspaces
					</NavLink>
				</div>
				<SupportDropdown />
				{myaccountDropdown}
			</Fragment>
		);

		return (
			user && (
				<div className="header-outer">
					<div className="product-header">
						<img
							className="logo"
							src={sdclogo}
							alt="SDC Logo"
							onClick={() => {
								this.props.history.push('/');
							}}
						/>
						{navigation}
					</div>
				</div>
			)
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.get('user') ? state.get('user').toJS() : null
});

const mapDispatchToProps = (dispatch) => ({
	logoutAction: (user) => dispatch(logout(user))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductBar));
