export const COLLECTIONS = {
	CASE: 'cases',
	PROJECT: 'projects',
	ACTIVITY: 'activities',
	TRIAL_SETS: 'trial_sets'
};

export const ACTIVITY = {
	CREATE: 'create',
	SHARE: 'share',
	UNSHARE: 'unshare',
	PUBLISH: 'publish',
	DELETE: 'delete'
};

export const S3_FOLDERS = {
	CASE: 'Cases',
	TEMP: 'Temp'
};

export const EMAIL = 'support.sdc@qrigroup.com';
