import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// Product Space Pages
import LoginForm from '../components/Layout/User/LoginForm';
import ResetPassword from '../components/Layout/User/ResetPassword';
import MainLandingPage from '../components/Layout/User/MainLandingPage';
import Membership from '../components/Layout/User/Membership';
import MyAccount from '../components/Layout/User/MyAccount';

const Case = lazy(() => import('../components/Layout/Case/Case'));
const FaqHome = lazy(() => import('../components/Layout/Faq/FaqHome'));
const FaqCategoryPage = lazy(() => import('../components/Layout/Faq/FaqCategoryPage'));
const ProjectsPage = lazy(() => import('../components/Layout/Project/ProjectsPage'));
const ProjectPage = lazy(() => import('../components/Layout/Project/ProjectPage'));
const QRIAdminPage = lazy(() => import('../components/Layout/Admin/QRIAdminPage'));

const ProductSpaceRoutes = ({ user }) => (
	<React.Fragment>
		<Route exact path="/reset" component={ResetPassword} />
		<Route
			exact
			path="/login"
			render={(props) => (
				<div className="page-wrap">
					<LoginForm {...props} user={user} />
				</div>
			)}
		/>
		<PrivateRoute path="/qriadmin" user={user} component={QRIAdminPage} />
		<PrivateRoute exact path="/myaccount" user={user} component={MyAccount} />
		<PrivateRoute exact path="/membership" user={user} component={Membership} />
		<PrivateRoute exact path="/experiment" user={user} component={Case} />
		<PrivateRoute exact path="/" user={user} component={MainLandingPage} />
		<PrivateRoute exact path="/main" user={user} component={ProjectsPage} />
		<PrivateRoute exact path="/projects" user={user} component={ProjectsPage} />
		<PrivateRoute exact path="/faq" user={user} component={FaqHome} />
		<PrivateRoute path="/faq/:category" user={user} component={FaqCategoryPage} />
		<PrivateRoute path="/project/:_id" user={user} component={ProjectPage} />
	</React.Fragment>
);

export default ProductSpaceRoutes;
