import { fromJS } from 'immutable';

const initialStates = {
	user: null,
	globalMsg: null,
	waitDecline: fromJS({ opLabel: null, flag: false }),
	cse: null,
	project: null,
	supportTopic: null,
	usageError: null,
	helpModal: ''
};

const helpModal = (state = initialStates.helpModal, action) => {
	switch (action.type) {
		case 'CLOSE_HELP':
			return '';
		case 'OPEN_HELP':
			return action.payload;
		default:
			return state;
	}
};

const usageError = (state = initialStates.usageError, action) => {
	switch (action.type) {
		case 'USAGE_ERROR':
			return action.error;
		default:
			return state;
	}
};

const supportTopic = (state = initialStates.supportTopic, action) => {
	switch (action.type) {
		case 'CLOSE':
			return null;
		case 'OPEN':
			return action.topic;
		default:
			return state;
	}
};

const cse = (state = initialStates.cse, action) => {
	switch (action.type) {
		case 'ENTER_CASE':
			return fromJS(action.case);
		default:
			return state;
	}
};

const project = (state = initialStates.project, action) => {
	switch (action.type) {
		case 'ENTER_PROJECT':
			return fromJS(action.project);
		default:
			return state;
	}
};

const waitDecline = (state = initialStates.waitDecline, action) => {
	switch (action.type) {
		case 'WAIT_DECLINE':
			return fromJS({ flag: action.event.flag, opLabel: action.event.opLabel });
		default:
			return state;
	}
};

const globalMsg = (state = initialStates.globalMsg, action) => {
	switch (action.type) {
		case 'SET_GLOBAL_MSG':
			return fromJS(action.msgs);
		case 'WAIT_PREPROCESSING':
			return fromJS(action.preprocessing.resultQCMsgs);
		default:
			return state;
	}
};

const user = (state = initialStates.user, action) => {
	switch (action.type) {
		case 'LOGIN':
			return fromJS(action.user);
		case 'LOGOUT':
			return null;
		default:
			return state;
	}
};

export default {
	user,
	waitDecline,
	globalMsg,
	cse,
	project,
	supportTopic,
	usageError,
	helpModal
};
