import { handleErrors } from './errors';
import { downloadFile } from './helpers';
import io from 'socket.io-client';

const socket = io();

const getAddress = (url, ...params) => {
	if (typeof url === 'string' || url instanceof String) {
		return url;
	} else {
		return url(...params);
	}
};

const post = (url, body, ...params) =>
	fetch(getAddress(url, ...params), {
		method: 'POST',
		headers: {
			'Content-type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(body)
	}).then(handleErrors);

const put = (url, body, ...params) =>
	fetch(getAddress(url, ...params), {
		method: 'PUT',
		headers: {
			'Content-type': 'application/json'
		},
		credentials: 'include',
		body: JSON.stringify(body)
	}).then(handleErrors);

const get = (url, ...params) => fetch(getAddress(url, ...params)).then(handleErrors);

const del = (url, ...params) =>
	fetch(getAddress(url, ...params), {
		method: 'DELETE',
		headers: {
			'Content-type': 'application/json'
		},
		credentials: 'include'
	}).then(handleErrors);

const authPost = (url, body) =>
	fetch(url, {
		method: 'POST',
		headers: {
			'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
		},
		credentials: 'include',
		body
	}).then(handleErrors);

// auth routes
const auth = (user) =>
	authPost('/auth/login', Object.keys(user).map((attr) => `${attr}=${encodeURIComponent(user[attr])}`).join('&'));
const createAccount = (user) =>
	authPost(
		'/auth/create_account',
		Object.keys(user).map((attr) => `${attr}=${encodeURIComponent(user[attr])}`).join('&')
	);
const sendResetEmail = (email) => authPost('/auth/request_reset_password', `email=${encodeURIComponent(email)}`);
const resetPassword = (pass, id) => authPost('/auth/reset_password', `password=${encodeURIComponent(pass)}&id=${id}`);
const checkResetExpiration = (query) => fetch(`/auth/reset_expired?id=${query}`).then(handleErrors);
const quit = () => authPost('/auth/logout');

const fetchAdminData = () => get('/auth/fetch_admin_data');
const supportEmail = (email, msg, topic) => post(`/auth/support_email`, { email, msg, topic });
const checkUsage = (type) => post('/case/checkUsage', { type });
const changeName = (userId, names) => post('/auth/change_name', { userId, names });

// S3 routes
const downloadFileFromS3 = (key, name = 'data') =>
	get(`/aws/s3_url?key=${encodeURIComponent(key)}`).then((r) => downloadFile(r.url, name));

// admin routes
const getAllUsers = () => get('/core/get_all_users', {});
const updateActive = (userId, boolean) => post('/core/update_active', { userId, boolean });
const getUsersForAdmin = () => get('/core/get_users_for_admin', {});
const createNewUser = (email, firstName, lastName, company, tier, admin) =>
	post('/auth/create_user_account', { email, firstName, lastName, company, tier, admin });
const deleteUser = (userId) => del(`/core/delete_user?userId=${userId}`, {});
const changeTier = (companyId, tier) => post('/core/change_tier', { companyId, tier });
const extendTrial = (userId) => post('/core/extend_trial', { userId });
const transferToExisting = (userId, userToTransferTo) =>
	post('/core/transfer_to_existing', { userId, userToTransferTo });
const transferToNew = (userId, newUser) => post('/core/transfer_to_new', { userId, newUser });

// project routes
const enterProject = (projectId) => post('/project/enterProject', { projectId });
const createProject = (projectName, projectDescription) =>
	post('/project/create_project', { projectName, projectDescription });
const hideProject = (projectId) => post('/project/hide_project', { projectId });
const unsubscribeProject = (projectId) => del(`/project/unsubscribe_project?projectId=${projectId}`, {});
const getUsersInProject = (projectId) => get(`/core/get_users?projectId=${projectId}`, {});
const shareProject = (userIds, projectId, accessLevel) => post('/project/share', { userIds, projectId, accessLevel });
const editProject = (projectId, projectName, projectDescription) =>
	post('/project/edit_project', { projectId, projectName, projectDescription });
const removeUserFromProject = (projectId, userIdToBeRemoved) =>
	post('/project/remove_user_from_project', { projectId, userIdToBeRemoved });
const preUploadFile = (file) => post('/case/uploadFile', file);
const postUploadFile = (file) => put('/case/uploadFile', file);
const deleteFile = (fileId, fileType) => post('/case/deleteFile', { fileId, fileType });

// core routes
const fetchDataAfterLogin = () => get('/core/fetch_data_after_login', {});
const fetchDataForLandingPage = () => get('/core/fetch_data_for_landing_page', {});
const fetchActivities = (pageSize, pageIndex, option) =>
	post('/core/fetch_activities', { pageSize, pageIndex, option });

// case routes
const transferCaseOwnership = (caseId, newUserId) => post('/case/transferCaseOwnership', { caseId, newUserId });
const useSampleData = () => post('/case/useSampleData', {});
const getWellData = (wellName, wellQueryProject) => post('/case/getWellData', { wellName, wellQueryProject });
const getDcaData = (wellName, runIndex) => post('/case/getDcaData', { wellName, runIndex });
const generateFieldInsight = (filters) => post('/case/generateFieldInsight', { filters });
const goToStep = (step) => post('/case/goToStep', { step });
const enterCase = (caseId) => post('/case/enterCase', { caseId });
const getCaseInfo = (stepName) => post('/case/info', { stepName });
const editCase = (caseId, caseName, caseDescription) => post('/case/edit_case', { caseId, caseName, caseDescription });
const initializeCase = (dataSetting) => post('/case/initialize', { dataSetting });
const dcaRunGotoIndex = (index) => post('/case/dcarunGotoIndex', { index });
const flagWell = (wells, status, statusValue) => post('/case/changeWellStatus', { wells, status, statusValue });
const getFilteredWells = (pageSize, pageIndex, filters, sortBy, useWellType) =>
	post('/case/filterWells', { pageSize, pageIndex, filters, sortBy, useWellType });
const getDcaRunData = () => post('/case/dcarunInfo', {});
const runDca = (dcaSetting, filteredWellNames, currentRunIndex) =>
	post('/case/addDcarun', { dcaSetting, filteredWellNames, currentRunIndex });
const fetchTableData = (pageSize, pageIndex, filters, sortBy, searchText, searchKey) =>
	post('./case/fetchdata', { pageSize, pageIndex, filters, sortBy, searchText, searchKey });
const fetchSampleData = (s3Loc, pageSize, pageIndex, filters, sortBy) =>
	post('./case/fetchsampledata', { s3Loc, pageSize, pageIndex, filters, sortBy });
const getCurrentCaseStep = () => get('/case/getCurrentStep', {});
const generateReport = (setting) => post('/case/report', { setting });
const deleteReport = (loc) => post('/case/deleteReport', { loc });
const updateEvent = (wellName, runIndex, eventStartIndices) =>
	post('/case/updateEvent', { wellName, runIndex, eventStartIndices });
const qcDcaParams = (wellName, runIndex, qcRes) => post('/case/qcDcaParams', { wellName, runIndex, qcRes });
const getTypeCurveInfo = () => get('/case/getTypeCurveInfo', {});
const createOrUpdateClustering = (clusteringSetting, hardSplitKey) =>
	post('/case/createOrUpdateClustering', { clusteringSetting, hardSplitKey });
const qcTypeCurveParams = (groupNumber, cluster, fittedModel) =>
	post('/case/qcTypeCurveParams', { groupNumber, cluster, fittedModel });
const updateWellTypes = (forceApply) => post('/case/updateWellTypes', { forceApply });
const exportData = (type) => post('/case/export', { type });
const generateWellGroupDeclines = (groupFeatures) => post('/case/generateWellGroupDeclines', { groupFeatures });
const getCasesFromProject = (projectId) => get(`/case/get_cases_from_project?projectId=${projectId}`, {});
const updateFavoriteCase = (caseId, projectId, isfavorite) =>
	post('/case/update_favorite_case', { caseId, projectId, isfavorite });
const createNewCase = (name, description) => post('/case/create', { name, description });
const cloneCase = (caseId) => post('/case/clone', { caseId });
const finalizeCase = () => post('/case/finalize', {});
const updateIsShared = (caseId) => post('/case/update_isshared', { caseId });
const getDataFile = (fileType) => post('/case/getDataFile', { fileType });
const deleteCase = (caseId) => post('/case/deleteCase', { caseId });
const runAutoPilot = () => post('/case/autopilot', {});
const setMode = (mode) => post('/case/setMode', { mode });

export default {
	transferCaseOwnership,
	useSampleData,
	getAllUsers,
	updateActive,
	getUsersForAdmin,
	createNewUser,
	deleteUser,
	changeTier,
	extendTrial,
	transferToExisting,
	transferToNew,
	changeName,
	fetchDataForLandingPage,
	fetchActivities,
	checkUsage,
	cloneCase,
	setMode,
	runAutoPilot,
	quit,
	generateFieldInsight,
	deleteCase,
	supportEmail,
	goToStep,
	getDataFile,
	exportData,
	generateWellGroupDeclines,
	updateWellTypes,
	qcTypeCurveParams,
	getTypeCurveInfo,
	createOrUpdateClustering,
	updateEvent,
	qcDcaParams,
	getDcaData,
	getWellData,
	flagWell,
	runDca,
	getDcaRunData,
	getFilteredWells,
	socket,
	auth,
	createAccount,
	sendResetEmail,
	resetPassword,
	checkResetExpiration,
	fetchAdminData,
	fetchSampleData,
	downloadFileFromS3,
	enterProject,
	createProject,
	hideProject,
	unsubscribeProject,
	getUsersInProject,
	shareProject,
	editProject,
	fetchDataAfterLogin,
	enterCase,
	getCurrentCaseStep,
	getCaseInfo,
	initializeCase,
	dcaRunGotoIndex,
	fetchTableData,
	preUploadFile,
	postUploadFile,
	deleteFile,
	generateReport,
	deleteReport,
	finalizeCase,
	editCase,
	getCasesFromProject,
	updateFavoriteCase,
	createNewCase,
	updateIsShared,
	removeUserFromProject
};
