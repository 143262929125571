import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { openSupport } from '../../../redux/actions';
import balloon from '../../../static/images/mem-baloons.svg';
import imgUser from '../../../static/images/mem-user.svg';
import team from '../../../static/images/mem-team.svg';
import building from '../../../static/images/mem-building.svg';
import bestValue from '../../../static/images/BestValue.svg';
import myAccount from '../../../static/images/MyAccountTier.svg';

const Membership = ({ openSupportAction, user }) => {
	const attributes = [
		{
			attribute: 'Optimized Event Detection',
			trial: 'checkmark',
			individual: 'checkmark',
			team: 'checkmark',
			business: 'checkmark'
		},
		{
			attribute: 'Curve Fitting QC and Adjustment',
			trial: 'checkmark',
			individual: 'checkmark',
			team: 'checkmark',
			business: 'checkmark'
		},
		{
			attribute: 'Auto-Tuned Clustering and Type Curving',
			trial: 'checkmark',
			individual: 'checkmark',
			team: 'checkmark',
			business: 'checkmark'
		},
		{
			attribute: 'CPU Time Limit (Hours)',
			trial: 10,
			individual: 40,
			team: 180,
			business: 1000
		},
		{
			attribute: 'File Storage (GB)',
			trial: null,
			individual: 5,
			team: 30,
			business: 100
		},
		{
			attribute: '# Users Per Account',
			trial: null,
			individual: null,
			team: 3,
			business: 10
		},
		{
			attribute: 'Team Collabration',
			trial: null,
			individual: null,
			team: 'checkmark',
			business: 'checkmark'
		}
	];

	return (
		<div className="membership">
			<div className="container">
				<div className="memb-content">
					<div className="col-container no-border-radius">
						<div className="memb-row">
							<div className="col-1-title">Choose Your Plan</div>
						</div>
						{attributes.map((x) => {
							return (
								<div className="memb-attr" key={x.attribute}>
									{x.attribute}
								</div>
							);
						})}
					</div>
					<div className="col-container">
						{user && user.companyData.tier_status === 0 && <img src={myAccount} className="best-value" />}
						<div className="memb-row">
							<div className="col-title">
								<img src={balloon} />
								<div className="col-2-title-text">Trial (2 weeks)</div>
							</div>
						</div>
						{attributes.map((x) => {
							if (x.trial === 'checkmark') {
								return (
									<div className="memb-row" key={x.attribute}>
										<div className="memb-check">
											<div className="memb-check__icon">
												<FontAwesomeIcon icon="check" size="sm" />
											</div>
										</div>
									</div>
								);
							}
							return (
								<div className="memb-row" key={x.attribute}>
									{x.trial}
								</div>
							);
						})}
					</div>
					<div className="col-container">
						{user && user.companyData.tier_status === 1 && <img src={myAccount} className="best-value" />}
						<div className="memb-row">
							<div className="col-title">
								<img src={imgUser} />
								<div className="col-2-title-text">Individual</div>
							</div>
						</div>
						{attributes.map((x) => {
							if (x.individual === 'checkmark') {
								return (
									<div className="memb-row" key={x.attribute}>
										<div className="memb-check">
											<div className="memb-check__icon">
												<FontAwesomeIcon icon="check" size="sm" />
											</div>
										</div>
									</div>
								);
							}
							return (
								<div className="memb-row" key={x.attribute}>
									{x.individual}
								</div>
							);
						})}
					</div>
					<div className="col-container">
						{user && user.companyData.tier_status === 2 && <img src={myAccount} className="best-value" />}
						<div className="memb-row">
							<div className="col-title">
								<img src={team} />
								<div className="col-2-title-text">Team</div>
							</div>
						</div>
						{attributes.map((x) => {
							if (x.team === 'checkmark') {
								return (
									<div className="memb-row" key={x.attribute}>
										<div className="memb-check">
											<div className="memb-check__icon">
												<FontAwesomeIcon icon="check" size="sm" />
											</div>
										</div>
									</div>
								);
							}
							return (
								<div className="memb-row" key={x.attribute}>
									{x.team}
								</div>
							);
						})}
					</div>
					<div className="col-container">
						{user && user.companyData.tier_status === 3 ? (
							<img src={myAccount} className="best-value" />
						) : (
							<img src={bestValue} className="best-value" />
						)}

						<div className="memb-row">
							<div className="col-title">
								<img src={building} />
								<div className="col-2-title-text">Business</div>
							</div>
						</div>
						{attributes.map((x) => {
							if (x.business === 'checkmark') {
								return (
									<div className="memb-row" key={x.attribute}>
										<div className="memb-check">
											<div className="memb-check__icon">
												<FontAwesomeIcon icon="check" size="sm" />
											</div>
										</div>
									</div>
								);
							}
							return (
								<div className="memb-row" key={x.attribute}>
									{x.business}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{user &&
			user.companyData.tier_status < 3 && (
				<div className="memb-contact memb-business" onClick={() => openSupportAction('Account Upgrade')}>
					Contact Us To Upgrade Account
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return { user: state.get('user') ? state.get('user').toJS() : null };
};

const mapDispatchToProps = (dispatch) => ({
	openSupportAction: (topic) => dispatch(openSupport(topic))
});

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
