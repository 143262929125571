import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TabSelector = ({ tabs, selectedTabName, containerClass, disabledTabs, onClickTab }) => {
	return (
		<div className={containerClass}>
			{tabs.map((value) => {
				const isDisabled = disabledTabs && disabledTabs.includes(value.name);
				return (
					<div
						key={value.name}
						className={`tab-option${selectedTabName === value.name ? ' selected' : ''}${isDisabled
							? ' disabled'
							: ''}`}
						onClick={!isDisabled ? () => onClickTab(value.name) : null}
						onKeyPress={null}
						role="button"
					>
						{value.icon && (
							<FontAwesomeIcon
								size="lg"
								icon={value.icon}
								className={`icon ${selectedTabName === value.name ? 'selected' : ''}`}
								color={value.color}
							/>
						)}
						<div className={`text ${selectedTabName === value.name ? 'selected' : ''}`}>{value.text}</div>
					</div>
				);
			})}
		</div>
	);
};

export default TabSelector;
