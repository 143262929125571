import React, { Component, Fragment } from 'react';
import { Col, Alert } from 'reactstrap';
import CircleText from '../../General/CircleText';

export const UserInitialComponent = ({ users }) => {
	return (
		<Fragment>
			{users.map((user) => {
				const color = user.access_level ? (user.access_level >= 2 ? 'green' : 'orange') : '#0077FF';
				return (
					<Col xs="auto" key={user._id} className="p-1">
						<CircleText
							text={
								(user.firstName || user.name.first)[0].toUpperCase() +
								(user.lastName || user.name.last)[0].toUpperCase()
							}
							color={color}
						/>
					</Col>
				);
			})}
		</Fragment>
	);
};

export class UserCardSimple extends Component {
	deleteHandler = () => {
		const { item, deleteHandlerParent } = this.props;
		deleteHandlerParent(item);
	};

	render() {
		const { item } = this.props;
		return (
			<Alert color="success" toggle={this.deleteHandler}>
				{`${item.firstName}, ${item.lastName}`}
			</Alert>
		);
	}
}
