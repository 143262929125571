import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Plot from 'react-plotly.js';
import Activities from './Activities';
import API from '../../../lib/api-store';
import BusyOverlay from '../../General/BusyOverlay';
import tipLogo from '../../../static/images/pageTipLogo.svg';
import { openSupport } from '../../../redux/actions';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const MainLandingPage = ({ user, openSupportAction, history }) => {
	const [ landingPageData, setLandingPageData ] = useState(null);

	useEffect(() => {
		API.fetchDataForLandingPage().then((r) => {
			console.log('MainLandingPage.js - Line 13 - r:', r);
			setLandingPageData(r);
		});
	}, []);

	if (!landingPageData) {
		return <BusyOverlay text="We are loading your data..." />;
	}

	const layout = {
		uirevision: true,
		showlegend: true,
		text: {
			font: { color: 'white' }
		},
		margin: { t: 0, b: 0, l: 16, r: 16 },
		legend: { xanchor: 'center', yanchor: 'middle', x: 0.5, y: -0.1, orientation: 'h', xref: 'container' }
	};

	let { usage, companyData, projects, cases } = landingPageData;

	// cpu usage
	const cpuUsageValues = [
		Math.round(companyData.usage.cpu_time / 60),
		Math.max(0, Math.round((companyData.limitations.cpu_time_per_month - companyData.usage.cpu_time) / 60))
	];
	const cpuUsageVerb = [ 'used', 'remaining' ];
	const colorCPU = [ '#5A51DE', '#2DD070' ];
	if (companyData.usage.cpu_time > usage.cpu_time + 1) {
		cpuUsageValues[0] = Math.round((companyData.usage.cpu_time - usage.cpu_time) / 60);
		cpuUsageValues.unshift(Math.round(usage.cpu_time / 60));
		cpuUsageVerb[0] = 'used by teammates';
		cpuUsageVerb.unshift('used by me');
		colorCPU.unshift('#3A552E');
	}

	const dataForCPUUsage = [
		{
			values: cpuUsageValues,
			labels: cpuUsageVerb, //.map((x) => 'CPU Time ' + x),
			text: cpuUsageValues.map(
				(x) =>
					`<span style="color:white;font-weight: bold">${Math.round(
						x * 10000 / cpuUsageValues.reduce((a, b) => a + b)
					) / 100}%</span>`
			),
			name: '',
			hovertemplate: '%{label}<br>%{value} hrs',
			textinfo: 'text',
			textposition: 'inside',
			type: 'pie',
			autosize: true,
			automargin: true,
			hole: 0.7,
			marker: {
				colors: colorCPU
			}
		}
	];

	const totalCPUUsage = cpuUsageValues.reduce((a, b) => a + b, 0);
	const layoutCPUUsage = {
		...layout,
		annotations: [
			{
				font: { size: 13, color: colorCPU[0] },
				showarrow: false,
				text: `<b>${companyData.usage.cpu_time >= 60
					? Math.round(companyData.usage.cpu_time / 60)
					: Math.round(companyData.usage.cpu_time / 0.6) / 100} of ${totalCPUUsage}<br>hrs Used</b>`,
				x: 0.5,
				y: 0.5
			}
		]
	};

	// storage usage
	const storageUsageValues = [
		Math.round(companyData.usage.storage),
		Math.max(0, Math.round(companyData.limitations.storage - companyData.usage.storage))
	];
	const storageUsageVerb = [ 'used', 'remaining' ];
	const colorStorage = [ '#5A51DE', '#2DD070' ];
	if (companyData.usage.storage > usage.storage + 1) {
		storageUsageValues[0] = Math.round(companyData.usage.storage - usage.storage);
		storageUsageValues.unshift(Math.round(usage.storage));
		storageUsageVerb[0] = 'used by teammates';
		storageUsageVerb.unshift('used by me');
		colorStorage.unshift('#3A552E');
	}

	const dataForStorageUsage = [
		{
			values: storageUsageValues,
			labels: storageUsageVerb,
			text: storageUsageValues.map(
				(x) =>
					`<span style="color:white;font-weight: bold">${Math.round(
						x * 10000 / storageUsageValues.reduce((a, b) => a + b)
					) / 100}%</span>`
			),
			name: '',
			hovertemplate: '%{label}<br>%{value} MB',
			textinfo: 'text',
			textposition: 'inside',
			type: 'pie',
			autosize: true,
			automargin: true,
			hole: 0.7,
			marker: {
				colors: colorStorage
			}
		}
	];

	const totalStorageUsage = storageUsageValues.reduce((a, b) => a + b, 0);
	const layoutStorageUsage = {
		...layout,
		annotations: [
			{
				font: { size: 13, color: colorStorage[0] },
				showarrow: false,
				text: `<b>${totalStorageUsage -
					storageUsageValues[storageUsageValues.length - 1]} of ${totalStorageUsage}<br>MB Used</b>`,
				x: 0.5,
				y: 0.5
			}
		]
	};

	// workspace summary
	const colorWorkspace = [ '#E37C22', '#01CAFF' ];
	const config = [
		[ '# created by me', (x) => x.iscreator, (n, x) => `You created ${n} ${x}${n > 1 ? 's' : ''}` ],
		[
			'# shared by teammates',
			(x) => !x.iscreator,
			(n, x) => `${n} ${x}${n > 1 ? 's were' : ' was'} shared with you`
		]
	];

	const dataForWorkspaceSummary = config.map(([ name, f, ft ], i) => {
		const x = [ projects.filter(f).length, cases.filter(f).length ];
		const y = [ 'Workspace', 'Case' ];
		const text = y.map((ye, i) => ft(x[i], ye.toLowerCase()));
		return {
			y,
			x,
			text,
			type: 'bar',
			orientation: 'h',
			hoverinfo: 'text',
			name,
			marker: {
				color: colorWorkspace[i]
			}
		};
	});

	const layoutSummary = {
		...layout,
		barmode: 'stack',
		hovermode: 'closest',
		yaxis: {
			automargin: true,
			fixedrange: true,
			autorange: 'reversed'
		},
		xaxis: {
			automargin: true,
			fixedrange: true,
			side: 'top'
		},
		bargap: 0.5
	};

	return (
		<div className="parent-grid">
			<div className="main-page-left">
				<div className="welcome-video-parent">
					<div className="welcome-video-left">
						<h2 className="light-blue-highlight">Welcome back {user.firstName}!</h2>
						<div className="prompt">Check out our new video about the Decline Curve Platform</div>
						<div className="prompt">
							Or read a quick start tutorial{' '}
							<span
								className="link"
								onClick={() => {
									history.push('/faq/Quick%20Start');
								}}
							>
								here
							</span>
						</div>
						{user.companyData.tier_status < 1 && (
							<div className="prompt days-left">
								<span className="light-blue-highlight">
									{moment(user.trial_expiration_date).diff(moment(new Date()), 'days')} days
								</span>{' '}
								left for your free trial. View our{' '}
								<span
									className="link"
									onClick={() => {
										history.push('/membership');
									}}
								>
									Membership Plans
								</span>
							</div>
						)}
						{user.companyData.tier_status < 3 && (
							<div className="prompt-upgrade">
								<div className="tip-icon">
									<img src={tipLogo} className="tip-logo" alt="tip" />
								</div>
								<span className="contact-us" onClick={() => openSupportAction('Account Upgrade')}>
									Contact us
								</span>{' '}
								to upgrade your {user.companyData.tier_status > 0 ? null : 'trial '}account
							</div>
						)}
					</div>
					<div className="welcome-video-right">
						<video
							controls
							controlsList="nodownload"
							src="https://speedwise-sdc-static-resources.s3.ap-south-1.amazonaws.com/SDC_Quick_Start_Video.mp4"
						/>
					</div>
				</div>

				<div className="charts">
					<div className="chart-container">
						<div className="header">Monthly CPU Usage</div>
						<Plot
							className="chart"
							data={dataForCPUUsage}
							useResizeHandler
							layout={layoutCPUUsage}
							config={{ displaylogo: false, displayModeBar: false }}
						/>
					</div>

					{user.companyData.tier_status > 0 && (
						<div className="chart-container">
							<div className="header">Storage Usage</div>
							<Plot
								className="chart"
								data={dataForStorageUsage}
								useResizeHandler
								layout={layoutStorageUsage}
								config={{ displaylogo: false, displayModeBar: false }}
							/>
						</div>
					)}

					<div className="chart-container">
						<div className="header">Workspace Summary</div>
						<Plot
							className="chart"
							data={dataForWorkspaceSummary}
							useResizeHandler
							layout={layoutSummary}
							config={{ displaylogo: false, displayModeBar: false }}
						/>
					</div>
				</div>
			</div>
			<div className="main-page-right">
				<Activities projects={projects} cases={cases} myUserId={user._id} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { user: state.get('user') ? state.get('user').toJS() : null };
};

const mapDispatchToProps = (dispatch) => ({
	openSupportAction: (topic) => dispatch(openSupport(topic))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLandingPage));
