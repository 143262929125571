import React, { useState, useEffect, Fragment } from 'react';
import API from '../../../lib/api-store';
import TabSelector from '../../common/TabSelector/TabSelector';
import ListContainer from '../../common/CardList/ListContainer';
import BusyOverlay from '../../General/BusyOverlay';
import { COLLECTIONS, ACTIVITY } from '../../../lib/constants';
import ActivityLink from './ActivityLink';
import { UserInitialComponent } from './UserComponents';

const ActivityCard = ({ item, cases, projects, selectedTabName, myUserId }) => {
	if (item.changeDate) {
		return <div className="change-date">{item.changeDate}</div>;
	}
	const { type, verb, project, case: cse, user } = item;

	const projectLink =
		project &&
		(projects.includes(project._id) ? (
			<ActivityLink type={COLLECTIONS.PROJECT} item={project} />
		) : (
			'"' + project.name + '"'
		));

	const caseLink =
		cse &&
		(cases.includes(cse._id) ? (
			<ActivityLink type={COLLECTIONS.CASE} item={cse} project={project} />
		) : (
			'"' + cse.name + '"'
		));

	const showShareWithYou = user && selectedTabName === 'my' && myUserId !== user._id;
	let header = null;
	let description = null;
	if (type === COLLECTIONS.PROJECT) {
		if (verb === ACTIVITY.CREATE) {
			header = 'Workspace Created';
			description = projectLink && <Fragment>Workspace {projectLink} was created.</Fragment>;
		} else if (verb === ACTIVITY.SHARE) {
			const { userIds } = item;
			const s = showShareWithYou ? 'you' : userIds.length + ' colleague' + (userIds.length > 1 ? 's' : '');
			header = 'Workspace Shared';
			description = projectLink && (
				<Fragment>
					Workspace {projectLink} was shared with {s}.
				</Fragment>
			);
		}
	} else if (type === COLLECTIONS.CASE) {
		if (verb === ACTIVITY.CREATE) {
			header = 'Case Created';
			description = caseLink &&
			projectLink && (
				<Fragment>
					Case {caseLink} was created under workspace {projectLink}.
				</Fragment>
			);
		} else if (verb === ACTIVITY.SHARE) {
			header = 'Case Shared';
			description = caseLink &&
			projectLink && (
				<Fragment>
					Case {caseLink} was shared under workspace {projectLink}.
				</Fragment>
			);
		} else if (verb === ACTIVITY.PUBLISH) {
			header = 'Case Completed';
			description = caseLink &&
			projectLink && (
				<Fragment>
					Case {caseLink} was completed under workspace {projectLink}.
				</Fragment>
			);
		} else if (verb === ACTIVITY.UNSHARE) {
			header = 'Case Unshared';
			description = caseLink &&
			projectLink && (
				<Fragment>
					Case {caseLink} was unshared under workspace {projectLink}.
				</Fragment>
			);
		} else if (verb === ACTIVITY.DELETE) {
			const { caseName } = item;
			header = 'Case Deleted';
			description = caseName &&
			projectLink && (
				<Fragment>
					Case {caseName} was deleted under workspace {projectLink}.
				</Fragment>
			);
		}
	}

	if (!description) {
		return null;
	}

	return (
		<div className="activity-card">
			{user && <UserInitialComponent users={[ user ]} />}
			<div className="right">
				<div className="header">
					<span className="text">{header}</span>
					<span className="time">{item.timeString}</span>
				</div>
				<div className="description">{description}</div>
			</div>
		</div>
	);
};
const Activities = ({ projects, cases, myUserId }) => {
	const [ selectedTabName, setSelectedTabName ] = useState('my');
	const [ activities, setActivities ] = useState([]);
	const [ pageCount, setPageCount ] = useState(0);
	const [ isBusy, setIsBusy ] = useState(false);
	const [ filters, setFilters ] = useState({});

	const tabs = [ { name: 'my', text: 'My Activities' }, { name: 'team', text: 'Team Activities' } ];

	const handleServerSideRender = (pageSize, pageIndex) => {
		setIsBusy(true);
		API.fetchActivities(pageSize, pageIndex, selectedTabName).then((r) => {
			if (r && r.activities && r.activities.length > 0) {
				const activitiesWithChangeDate = [];
				let previousTimeStamp = null;
				r.activities.forEach((x) => {
					const d = new Date(x.date);
					const timeString = d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
					d.setHours(0, 0, 0, 0);
					const timeStamp = d.getTime();
					if (!previousTimeStamp || previousTimeStamp !== timeStamp) {
						activitiesWithChangeDate.push({
							changeDate: d.toLocaleDateString('en-US', {
								month: 'short',
								day: 'numeric',
								year: 'numeric'
							})
						});
						previousTimeStamp = timeStamp;
					}
					activitiesWithChangeDate.push({ ...x, timeString, timeStamp });
				});
				setActivities(activitiesWithChangeDate);
			} else {
				setActivities([]);
			}
			setPageCount(r ? r.pageCount : 0);
			setIsBusy(false);
		});
	};

	const content = (
		<ListContainer
			items={activities}
			Card={ActivityCard}
			cardProps={{
				projects: projects.map((x) => x._id),
				cases: cases.map((x) => x._id),
				selectedTabName,
				myUserId
			}}
			listContainerClassName="activity-cards"
			filters={filters}
			pageSizeOptions={[ 8, 12, 15 ]}
			handleServerSideRender={handleServerSideRender}
			controlledPageCount={pageCount}
		/>
	);

	return (
		<Fragment>
			{isBusy && <BusyOverlay />}
			<TabSelector
				tabs={tabs}
				selectedTabName={selectedTabName}
				containerClass="activity-tab-selector"
				onClickTab={(e) => {
					setFilters({ ...filters });
					setSelectedTabName(e);
				}}
			/>
			{content}
			{(!activities || activities.length === 0) && <div className="no-activity">No Activity</div>}
		</Fragment>
	);
};

export default Activities;
