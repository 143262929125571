export const login = (user) => ({ type: 'LOGIN', user });
export const logout = () => ({ type: 'LOGOUT' });
export const enterProject = (project) => ({ type: 'ENTER_PROJECT', project });
export const enterCase = (cse) => ({ type: 'ENTER_CASE', case: cse });
export const waitDecline = (event) => ({ type: 'WAIT_DECLINE', event });
export const setGlobalMsg = (msgs) => ({ type: 'SET_GLOBAL_MSG', msgs });
export const setUsageError = (err) => ({ type: 'USAGE_ERROR', error: err });
export const closeSupport = () => ({ type: 'CLOSE' });
export const openSupport = (topic) => ({ type: 'OPEN', topic });
export const closeHelpModal = () => ({ type: 'CLOSE_HELP' });
export const openHelpModal = (payload) => ({ type: 'OPEN_HELP', payload });
