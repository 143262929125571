import Fuse from 'fuse.js';
import Papa from 'papaparse';
import moment from 'moment';
import { ACCEPTABLE_SIZE_MB } from './errors';

///////////////////////
// Library Variables //
///////////////////////
const SI_PREFIX = [
	{ value: 1e12, symbol: 'T' },
	{ value: 1e9, symbol: 'B' },
	{ value: 1e6, symbol: 'MM' },
	{ value: 1e3, symbol: 'k' }
];

const BYTE_PREFIX = [
	{ value: 1e12, symbol: 'T' },
	{ value: 1e9, symbol: 'G' },
	{ value: 1e6, symbol: 'M' },
	{ value: 1e3, symbol: 'K' }
];

/**
 * Converts integer to a formatted string representing a
 * decimal with significant figures and condensed standard unit appended.
 *
 * @param  {Number} num         Number to be formatted.
 * @param  {Number} digits      Number of digits in front of decimal point.
 * @param  {String} [unit='']   Base unit to be appended.
 * @return {String}             Formatted string.
 */
export const shortenNum = (num, digits, unit = '') => {
	if (num === undefined || typeof num !== 'number') {
		return num;
	}

	if (num < 0.001) {
		return `0${unit ? `${unit}` : ''}`;
	}

	const conversion = unit ? SI_PREFIX : BYTE_PREFIX;

	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	for (let i = 0; i < SI_PREFIX.length; i += 1) {
		if (num >= SI_PREFIX[i].value) {
			return `${(num / SI_PREFIX[i].value).toFixed(digits).replace(rx, '$1')} ${conversion[i].symbol}${unit}`;
		}
	}

	const finalNum = num.toFixed(digits);

	if (finalNum === '0') {
		return `0${unit ? `${unit}` : ''}`;
	}

	return `${num.toFixed(digits).replace(rx, '$1')}${unit ? `${unit}` : ''}`;
};

export const argMax = (a) => a.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);

export const parseMD = (text, separator = '####') => {
	let textList = text.split(separator);
	textList.shift();
	return textList.map((s) => {
		return separator + s;
	});
};

export const r2Score = (arr) => {
	const ssRes = arr.reduce((obj, e) => obj + (e.y - e.f) ** 2, 0);
	const mean = arr.reduce((obj, e) => obj + e.y, 0) / arr.length;
	const ssTot = arr.reduce((obj, e) => obj + (e.y - mean) ** 2, 0);
	return 1 - ssRes / ssTot;
};

export const mse = (arr) => {
	return arr.reduce((obj, e) => obj + (e.y - e.f) ** 2, 0) / arr.length;
};

export const relativeErr = (arr) => {
	return arr.reduce((obj, e) => obj + Math.abs(e.y - e.f), 0) / arr.reduce((obj, e) => obj + Math.abs(e.y), 0);
};

export const getAccuracy = (arr) => ({
	r2: r2Score(arr),
	mse: mse(arr),
	re: relativeErr(arr)
});

export const arrayToObject = (array, keyField, valueField) => {
	let obj = {};
	for (const item of array) {
		obj[item[keyField]] = item[valueField];
	}
	return obj;
};

export const stringToDate = (s) => new Date(Date.parse(s));

export const getRandomColor = () => {
	var letters = '0123456789ABCDEF';
	var color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

export const beautifyTimeElapsed = (milliseconds) => {
	if (milliseconds < 1000) {
		return `${Math.round(milliseconds)} ms`;
	} else if (milliseconds < 6e4) {
		return `${Math.round(milliseconds / 1000)} secs`;
	} else {
		return `${Math.round(milliseconds / 6e4)} minutes`;
	}
};

export const fixDigits = (v, fcn) => {
	if (Array.isArray(v)) {
		return v.map((x) => fixDigits(x, fcn));
	}
	const f = fcn || Math.round;
	if (Number.isInteger(v)) return v;
	if (v < 1000) return f(v * 1000) / 1000;
	return f(v);
};

export const beautifyValue = (v) => {
	const vtype = typeof v;
	if (vtype == 'number') {
		if (Number.isInteger(v)) return v;
		if (v < 100) return Math.round(v * 1000) / 1000;
		if (v < 1000) return Math.round(v * 10) / 10;
		if (v < 100000) return Math.round(v);
		else return v.toExponential(3);
	} else if (vtype == 'boolean') {
		return v.toString();
	} else if (vtype == 'string') {
	} else if (vtype == 'object') {
		if (v instanceof Date && !isNaN(v.valueOf())) {
			v = v.getMonth() + 1 + '/' + v.getFullYear();
		} else if (Array.isArray(v)) {
			return v.join(', ');
		} else if (v && v.hasOwnProperty('content')) {
			return v.content;
		}
	}
	return v;
};

export const togglableClassName = (condition, className) => {
	return condition ? className : `${className} d-none`;
};

export const specifyCamelString = (x) => {
	return (
		x
			.replace(/([A-Z])/g, ' $1')
			// uppercase the first character
			.replace(/^./, function(str) {
				return str.toUpperCase();
			})
	);
};

export const getFilteredData = (
	data,
	{ searchText = '', searchKeys = [], pageSize = null, pageIndex = null, filters = null, sortBy = null } = {}
) => {
	if (filters) {
		data = data.filter((el) => {
			for (let key of Object.keys(filters)) {
				const val = el[key];
				const filterVal = filters[key];
				if (typeof val === 'string' || val instanceof String) {
					if (!filterVal.includes(val)) {
						return false;
					}
				} else {
					if (val > filterVal[1] || val < filterVal[0]) {
						return false;
					}
				}
			}

			return true;
		});
	}

	if (searchText && searchKeys) {
		const fuse = new Fuse(data, { keys: searchKeys, shouldSort: true, threshold: 0.6 });
		data = fuse.search(searchText);
	}

	if (sortBy) {
		data.sort((a, b) => {
			for (let i = sortBy.length - 1; i >= 0; i--) {
				const sort = sortBy[i];
				const { id, desc } = sort;
				if (!a.hasOwnProperty(id)) {
					return 1;
				} else if (!b.hasOwnProperty(id)) {
					return -1;
				}
				if (a[id] !== b[id]) {
					return (a[id] < b[id]) ^ desc ? -1 : 1;
				}
			}
			return 0;
		});
	}

	let slicedData = data;
	let pageCount = 1;

	if (pageSize) {
		const startRow = pageSize * pageIndex;
		const endRow = startRow + pageSize;
		pageCount = Math.ceil(data.length / pageSize);
		slicedData = data.slice(startRow, endRow);
	}

	return { slicedData, pageCount };
};

export const uploadFile = (url, file, onProgressHandler) =>
	new Promise((resolve) => {
		const xhr = new XMLHttpRequest();
		xhr.open('PUT', url, true);
		// Send the proper header information along with the request
		xhr.setRequestHeader('Content-Type', 'application/octet-stream');
		xhr.upload.addEventListener('progress', onProgressHandler, false);
		// Call a function when the state changes
		xhr.onreadystatechange = function onReadyStateChange() {
			if (this.readyState === XMLHttpRequest.DONE) {
				resolve(xhr);
			}
		};
		xhr.send(file);
	});

export const downloadFile = (url, name) =>
	new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'arraybuffer';
		xhr.onload = function onload() {
			if (this.status === 200) {
				let filename = name;
				const type = xhr.getResponseHeader('Content-Type');
				const blob =
					typeof File === 'function'
						? new File([ this.response ], filename, { type })
						: new Blob([ this.response ], { type });
				if (typeof window.navigator.msSaveBlob !== 'undefined') {
					window.navigator.msSaveBlob(blob, filename);
				} else {
					const URL = window.URL || window.webkitURL;
					const downloadUrl = URL.createObjectURL(blob);
					if (!filename) {
						filename = 'data.csv';
					}
					// use HTML5 a[download] attribute to specify filename
					const a = document.createElement('a');
					// safari doesn't support this yet
					if (typeof a.download === 'undefined') {
						window.location = downloadUrl;
					} else {
						a.href = downloadUrl;
						a.download = filename;
						document.body.appendChild(a);
						a.click();
					}
					setTimeout(() => {
						URL.revokeObjectURL(downloadUrl);
					}, 100); // cleanup
				}
				resolve({ success: true });
			} else {
				const decodedString = String.fromCharCode.apply(null, new Uint8Array(this.response));
				const obj = JSON.parse(decodedString);
				reject(obj);
			}
		};
		xhr.send();
	});

export const determineValType = (val) => {
	if (typeof val === 'number') {
		return 'number';
	}
	if (val instanceof Date) {
		return 'date';
	}
	if (typeof val === 'boolean') {
		return 'string';
	}
	if (typeof val === 'string') {
		if (
			moment(
				val,
				[ 'MM-DD-YYYY', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'M/D/YYYY', 'D-MMM-YY', 'D-MMM-YYYY' ],
				true
			).isValid()
		) {
			return 'date';
		} else if (val === 'NaN') {
			return 'number';
		} else if (val === 'NaT') {
			return 'date';
		}
		return 'string';
	}

	return typeof val;
};

export const decimalToDate = (decimalDate) => {
	if (!decimalDate) return null;
	if (Array.isArray(decimalDate)) {
		return decimalDate.map((x) => decimalToDate(x));
	} else {
		const year = Math.round(decimalDate);
		const reminder = (decimalDate - year) * 12;
		const month = Math.round(reminder);
		return new Date(year, month);
	}
};

export const dateToDecimal = (date) => {
	if (Array.isArray(date)) {
		return date.map((x) => dateToDecimal(x));
	} else {
		const month = date.getMonth();
		const year = date.getFullYear();
		return year + month / 12;
	}
};

// check if the signature matches
export const doesSignatureMatch = (signatureRequired, signature) => {
	for (const k of Object.keys(signatureRequired)) {
		if (!(signature.hasOwnProperty(k) && signature[k] === signatureRequired[k])) {
			return false;
		}
	}
	return true;
};

export const parseCsv = (file) =>
	new Promise((resolve, reject) => {
		const columnTypes = {};
		Papa.parse(file, {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: true,
			step: function(row) {
				Object.entries(row.data).forEach(([ col, val ]) => {
					columnTypes[col] = determineValType(val);
				});
			},
			complete: function() {
				console.log('complete');
				resolve({ columnTypes, file });
			},
			error: function() {
				console.log('Error');
				reject('err');
			}
		});
	});

export const isFileAcceptable = async (acceptedExt, file, requiredSignature) => {
	console.log('REQD', requiredSignature);
	let extension = file.name.split('.');
	extension = '.' + extension[extension.length - 1].toLowerCase();

	const checks = [
		{
			check: acceptedExt.includes(extension),
			code: 'INCORRECT_TYPE',
			errorMsg: `File should be one of the following types ${acceptedExt.join(', ')}`
		},
		{
			check: file.size / 1024 / 1024 < ACCEPTABLE_SIZE_MB,
			errorMsg: `File over ${ACCEPTABLE_SIZE_MB} MB`,
			code: 'FILE_SIZE_LIMIT'
		}
	];

	const errors = [];
	let isAcceptable = Object.values(checks).reduce((prev, { check, code, errorMsg }) => {
		if (!check) {
			errors.push({ code, msg: errorMsg });
		}
		return prev && check;
	}, true);
	console.log('IsAccep', isAcceptable);
	if (isAcceptable) {
		try {
			const value = await parseCsv(file);
			const { columnTypes } = value;
			if (requiredSignature) {
				isAcceptable = doesSignatureMatch(requiredSignature, columnTypes);
			}
			if (!isAcceptable) {
				errors.push({ code: 'INVALID_SIGNATURE', msg: 'File signature is invalid!' });
			}
			return { errors, isAcceptable, extension, ...value };
		} catch (e) {
			return { errors, isAcceptable: false, extension, file };
		}
	}
	return { errors, isAcceptable: false, extension, file };
};
