export const ACCEPTABLE_SIZE_MB = 100;

export const handleErrors = (response) => {
	const status = response.status;
	return response.json().then((json) => {
		if (status !== 200) {
			console.log(json);
			throw Error(json.error || 'Server Error');
		}
		return json;
	});
};
