import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPagination from 'react-js-pagination';

const Pagination = ({
	pageCount,
	pageIndex,
	pageSize,
	handleGotoPage,
	handleSetPageSize,
	pageSizeOptions = [ 5, 10, 15 ]
}) => {
	if (!pageSizeOptions || (pageSize <= pageSizeOptions[0] && pageCount === 1)) {
		return null;
	}
	const hidableComponents = pageCount > 1 && (
		<ReactPagination
			activePage={pageIndex + 1}
			itemsCountPerPage={pageSize}
			totalItemsCount={pageCount * pageSize}
			onChange={(e) => handleGotoPage(e - 1)}
			innerClass="pagination-inner"
			prevPageText={<FontAwesomeIcon icon="chevron-left" />}
			nextPageText={<FontAwesomeIcon icon="chevron-right" />}
			firstPageText={<FontAwesomeIcon icon="step-backward" />}
			lastPageText={<FontAwesomeIcon icon="step-forward" />}
			pageRangeDisplayed={5}
			// hideDisabled={true}
			// hideFirstLastPages={true}
		/>
	);

	return (
		<div className="pagination">
			<select
				className="mr-2 p-1"
				value={pageSize}
				onChange={(e) => {
					const newPageSize = Number(e.target.value);
					if (newPageSize > pageSize) {
						handleGotoPage(0);
					}
					handleSetPageSize(newPageSize);
				}}
			>
				{pageSizeOptions.map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						{pageSize} per page
					</option>
				))}
			</select>
			{hidableComponents}
		</div>
	);
};

export default Pagination;
