import React, { Component, useState, useEffect, useMemo, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../General/Pagination';
import { getFilteredData } from '../../../lib/helpers';
import { debounce } from 'throttle-debounce';

const ListContainer = ({
	items,
	Card,
	cardProps = {},
	listContainerClassName = 'list-container-default',
	controlledPageCount = 5,
	pageSizeOptions = [ 5, 10, 20 ],
	sortBy = null,
	filters = null,
	searchText = '',
	searchKeys = null,
	preSelectedItem = null,
	handleSelectItem = null,
	handleServerSideRender = null
}) => {
	let [ selectedItem, setSelectedItem ] = useState(null);
	let [ pageSize, setPageSize ] = useState(pageSizeOptions[0]);
	let [ pageIndex, setPageIndex ] = useState(0);
	let [ flag, setFlag ] = useState(false);

	useEffect(() => {
		if (pageSizeOptions) {
			setPageSize(pageSizeOptions[0]);
		}
	}, pageSizeOptions);

	useEffect(
		() => {
			setSelectedItem(preSelectedItem);
		},
		[ preSelectedItem ]
	);

	const handlePreviousPage = () => {
		setPageIndex(pageIndex - 1);
	};
	const handleNextPage = () => {
		setPageIndex(pageIndex + 1);
	};
	const handleGotoPage = (pageIndex) => {
		setPageIndex(pageIndex > pageCount - 1 ? pageCount - 1 : pageIndex < 0 ? 0 : pageIndex);
	};
	const handleSetPageSize = (pageSize) => {
		setPageSize(pageSize > 0 ? pageSize : 1);
	};

	const handleSelect = (item) => {
		setSelectedItem(item);
		if (handleSelectItem) {
			handleSelectItem(item);
		}
	};

	let [ displayItems, filteredPageCount ] = useMemo(
		() => {
			if (handleServerSideRender) {
				return [ items, null ];
			} else {
				const { slicedData, pageCount } = getFilteredData(items, {
					filters,
					sortBy,
					pageSize,
					pageIndex,
					searchText,
					searchKeys
				});
				return [ slicedData, pageCount ];
			}
		},
		[ items, handleServerSideRender, filters, sortBy, pageSize, pageIndex, searchText, searchKeys ]
	);

	const debouncedFetch = useMemo(
		() => {
			if (handleServerSideRender) return debounce(300, handleServerSideRender);
			else return null;
		},
		[ handleServerSideRender ]
	);

	useEffect(
		() => {
			if (pageIndex !== 0) setPageIndex(0);
			else setFlag(!flag);
		},
		[ filters, searchText, searchKeys ]
	);

	useEffect(
		() => {
			if (handleServerSideRender) {
				debouncedFetch(pageSize, pageIndex, filters, sortBy);
			}
		},
		[ pageSize, pageIndex, sortBy, flag ]
	);

	const pageCount = handleServerSideRender ? controlledPageCount : filteredPageCount;
	const canPreviousPage = pageIndex > 0;
	const canNextPage = pageIndex < pageCount - 1;

	const pagination =
		pageSizeOptions && pageSizeOptions.length && (items.length > pageSizeOptions[0] || pageCount > 1) ? (
			<Pagination
				canPreviousPage={canPreviousPage}
				canNextpage={canNextPage}
				pageCount={pageCount}
				pageIndex={Math.max(0, pageIndex)}
				pageSize={pageSize}
				pageSizeOptions={pageSizeOptions}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				handleGotoPage={handleGotoPage}
				handleSetPageSize={handleSetPageSize}
			/>
		) : null;

	return (
		<Fragment>
			<div className={listContainerClassName}>
				{displayItems.map((item, i) => (
					<Card
						item={item}
						key={`card${i}`}
						handleSelect={handleSelect}
						selected={selectedItem === item}
						{...cardProps}
					/>
				))}
			</div>
			{pagination}
		</Fragment>
	);
};
export default ListContainer;
