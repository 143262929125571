import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import star from '../../../static/images/star.svg';
import moment from 'moment';
import { Col, Row, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import API from '../../../lib/api-store';
import { login } from '../../../redux/actions';
import { set } from 'immutable';

const MyAccount = ({ user, loginAction, history }) => {
	const [ tierStatus, setTierStatus ] = useState('');
	const [ daysLeft, setDaysLeft ] = useState('');
	const [ nameEditing, setNameEditing ] = useState(false);
	const [ values, setValues ] = useState({ firstName: '', lastName: '' });
	let { firstName, lastName } = values;
	const [ sendResetEmail, setSendResetEmail ] = useState(false);
	const [ emailSent, setEmailSent ] = useState(false);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};
	const escFunction = useCallback((event) => {
		if (event.keyCode === 27) {
			setNameEditing(false);
			setSendResetEmail(false);
		}
	}, []);
	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);
	useEffect(
		() => {
			switch (user.companyData.tier_status) {
				case 0:
					setTierStatus('Trial Account');
					break;
				case 1:
					setTierStatus('Individual');
					break;
				case 2:
					setTierStatus('Team');
					break;
				default:
					setTierStatus('Business');
					break;
			}
			if (user.trial_expiration_date !== undefined) {
				setDaysLeft(moment(user.trial_expiration_date).diff(moment(new Date()), 'days'));
			}
			setValues({ firstName: user.firstName, lastName: user.lastName });
		},
		[ user ]
	);
	useEffect(
		() => {
			if (emailSent) {
				setSendResetEmail(false);
				setTimeout(() => {
					setEmailSent(false);
				}, 3000);
			}
		},
		[ emailSent ]
	);
	const nameForm = (
		<div>
			<Row form>
				<Col md={4}>
					<Input value={firstName} name="firstName" onChange={handleInputChange} />
				</Col>
				<Col md={4}>
					<Input value={lastName} name="lastName" onChange={handleInputChange} />
				</Col>
				<button
					className="secondary-button"
					disabled={!firstName || !lastName}
					onClick={() => {
						setNameEditing(!nameEditing);
						loginAction({ ...user, firstName, lastName });
						API.changeName(user._id, values);
					}}
				>
					Submit
				</button>
			</Row>
		</div>
	);

	const passwordButton = (
		<div>
			<button
				className="secondary-button"
				onClick={() => {
					API.sendResetEmail(user.email);
					setEmailSent(true);
				}}
			>
				Send Reset Email
			</button>
		</div>
	);
	return (
		<div className="container">
			<div className="my-account-container">
				<div className="account-header">My Account</div>
				<div className="account-grid">
					<div className="details-row column1">Name</div>
					<div className="details-row column2">{nameEditing ? nameForm : `${firstName} ${lastName}`}</div>
					<div className="details-row column3">
						<FontAwesomeIcon
							className="account-edit"
							onClick={() => setNameEditing(!nameEditing)}
							icon="pencil-alt"
							size="sm"
						/>
					</div>
					<div className="details-row column1">Password</div>
					<div className="details-row column2">
						{emailSent ? <Alert>Email Sent!</Alert> : sendResetEmail ? passwordButton : '********'}
					</div>
					<div className="details-row column3">
						<FontAwesomeIcon
							className="account-edit"
							onClick={() => {
								setSendResetEmail(!sendResetEmail);
							}}
							icon="pencil-alt"
							size="sm"
						/>
					</div>
				</div>
			</div>
			<div className="subscription-container">
				<div className="subscription-header">
					<div>Subscription</div>
					<div className="member-plan" onClick={() => history.push('/membership')}>
						<img src={star} className="star" />
						View Membership Plans
					</div>
				</div>
				<div className="subscription-grid">
					<div className="details-row column1">Account</div>
					<div className="details-row">{tierStatus}</div>
					<div className="details-row column3">{tierStatus === 'Trial Account' && 'Trial days left'}</div>
					<div className="details-row">{tierStatus === 'Trial Account' && `${daysLeft} days left`}</div>
					<div className="details-row column1">Storage Limit</div>
					<div className="details-row">{user.companyData.limitations.storage} MB</div>
					<div className="details-row column3">Storage Remaining</div>
					<div className="details-row">
						{Math.round(user.companyData.limitations.storage - user.companyData.usage.storage)}
					</div>
					<div className="details-row column1">CPU Hrs Limit</div>
					<div className="details-row">
						{Math.round(user.companyData.limitations.cpu_time_per_month / 60)}
					</div>
					<div className="details-row column3">CPU Hrs Remaining</div>
					<div className="details-row">
						{Math.round(
							(user.companyData.limitations.cpu_time_per_month - user.companyData.usage.cpu_time) / 60
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { user: state.get('user') ? state.get('user').toJS() : null };
};

const mapDispatchToProps = (dispatch) => ({
	loginAction: (user) => dispatch(login(user))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccount));
