import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Form, FormGroup, Label, Alert, Input } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import API from '../../../lib/api-store';
import { connect } from 'react-redux';
import { openSupport, closeSupport } from '../../../redux/actions';

const topicOptions = [
	'Workspace',
	'Case',
	'Upload Data',
	'Well DCA Run',
	'Type Curve',
	'Account Upgrade',
	'Other'
].map((x) => ({ label: x, value: x }));

const SupportDropdown = ({ history, user, openSupportAction, closeSupportAction, supportTopic }) => {
	const [ dropdownOpen, setDropdownOpen ] = useState(false);
	const [ contactForm, setContactForm ] = useState(false);
	const [ helpText, setHelpText ] = useState('');
	const [ faqPage, setFaqPage ] = useState(false);
	const [ emailSent, setEmailSent ] = useState(false);

	useEffect(
		() => {
			if (supportTopic) {
				setDropdownOpen(true);
				setContactForm(true);
			}
		},
		[ supportTopic ]
	);

	useEffect(
		() => {
			if (dropdownOpen === false) {
				closeSupportAction();
				setContactForm(false);
				setEmailSent(false);
			}
		},
		[ dropdownOpen ]
	);

	useEffect(
		() => {
			if (history.location.pathname == '/faq') {
				return setFaqPage(true);
			}
			setFaqPage(false);
		},
		[ history.location.pathname ]
	);

	useEffect(
		() => {
			if (emailSent) {
				setTimeout(() => {
					setDropdownOpen(false);
				}, 3000);
			}
		},
		[ emailSent ]
	);

	const form = emailSent ? (
		<Alert className="help-form sent" color="success">
			Thank you for your feedback. We will respond within 1-3 business days
		</Alert>
	) : (
		<Form className="help-form">
			<div className="help-header">
				Leave us a message, our team will get back to you within 1-3 business days.
			</div>
			<FormGroup id="topic">
				<Label>How can we help you?</Label>
				<Select
					placeholder="Select Topic"
					options={topicOptions}
					type="select"
					value={supportTopic ? { value: supportTopic, label: supportTopic } : null}
					onChange={(e) => {
						openSupportAction(e.value);
					}}
				/>
			</FormGroup>
			<FormGroup>
				<Input
					type="textarea"
					value={helpText}
					name="helpText"
					placeholder="Comments, Questions, Concerns"
					rows={8}
					onChange={(e) => {
						setHelpText(e.target.value);
					}}
				/>
			</FormGroup>
			<button
				className="primary-button"
				onClick={(e) => {
					e.preventDefault();
					API.supportEmail(user.email, helpText, supportTopic);
					setEmailSent(true);
				}}
				disabled={!supportTopic || !helpText || helpText.length < 10}
			>
				Submit
			</button>
		</Form>
	);
	return (
		<Dropdown
			className={faqPage ? 'nav active' : 'nav'}
			active
			inNavbar={true}
			isOpen={dropdownOpen}
			toggle={() => {
				setDropdownOpen(!dropdownOpen);
			}}
		>
			<DropdownToggle nav caret>
				Support
			</DropdownToggle>
			<DropdownMenu right>
				{contactForm ? (
					form
				) : (
					<div>
						<DropdownItem
							toggle={false}
							onClick={() => {
								setContactForm(!contactForm);
							}}
						>
							<FontAwesomeIcon icon="envelope" style={{ marginRight: '0.5rem' }} />
							Contact Us
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem
							onClick={() => {
								history.push('/faq');
							}}
						>
							<FontAwesomeIcon icon="question-circle" style={{ marginRight: '0.5rem' }} />
							Frequently Asked Questions
						</DropdownItem>
					</div>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

const mapStateToProps = (state) => ({
	user: state.get('user') ? state.get('user').toJS() : null,
	supportTopic: state.get('supportTopic')
});

const mapDispatchToProps = (dispatch) => ({
	openSupportAction: (topic) => dispatch(openSupport(topic)),
	closeSupportAction: () => dispatch(closeSupport())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportDropdown));
