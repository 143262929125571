import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter, ConnectedRouter } from 'connected-react-router/immutable';
import { Provider } from 'react-redux';
import { Map } from 'immutable';

import './fonts/Roboto-Medium.ttf';
import './fonts/Roboto-Bold.ttf';
import './fonts/Roboto-Regular.ttf';

import {
	faEnvelope,
	faAngleLeft,
	faAsterisk,
	faMapMarker,
	faBars,
	faCaretDown,
	faCaretUp,
	faInfoCircle,
	faSortAmountUp,
	faSortAmountDown,
	faLock,
	faLockOpen,
	faFilter,
	faFilePdf,
	faKey,
	faUpload,
	faTachometerAlt,
	faStar,
	faStopCircle,
	faPlane,
	faExclamationCircle,
	faCopy,
	faExclamationTriangle,
	faCheck,
	faSignInAlt,
	faPlus,
	faArrowCircleLeft,
	faArrowCircleRight,
	faHistory,
	faTimes,
	faArrowCircleUp,
	faCircleNotch,
	faCheckCircle,
	faTimesCircle,
	faFileAlt,
	faTable,
	faSpinner,
	faDownload,
	faEllipsisH,
	faTrash,
	faUser,
	faHome,
	faCog,
	faHeart,
	faThLarge,
	faCompress,
	faExpand,
	faShareAlt,
	faChartBar,
	faClone,
	faUsers,
	faPencilAlt,
	faTools,
	faListAlt,
	faChartLine,
	faRedo,
	faUndo,
	faFile,
	faDatabase,
	faCalendarAlt,
	faCalendar,
	faArrowUp,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faCircle,
	faSquare,
	faQuestionCircle,
	faPlusCircle,
	faMinusCircle,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronCircleUp,
	faChevronCircleDown,
	faToggleOn,
	faToggleOff,
	faStepBackward,
	faStepForward,
	faChevronLeft,
	faChevronRight,
	faSearch,
	faClock,
	faPauseCircle,
	faPlayCircle,
	faEye,
	faPaperPlane,
	faCloudUploadAlt,
	faFlag,
	faExternalLinkAlt,
	faMapMarkerAlt,
	faFileCsv,
	faFlask
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import App from './components/Layout/App';
import rootReducer from './redux/index';
import { loadState, saveState } from './lib/local-storage';
import socketMiddleware from './redux/socket';
import API from './lib/api-store';

import './styles/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

library.add(
	faFlask,
	faEnvelope,
	faAngleLeft,
	faFileCsv,
	faAsterisk,
	faMapMarker,
	faMapMarkerAlt,
	faCaretDown,
	faCaretUp,
	faInfoCircle,
	faSortAmountUp,
	faSortAmountDown,
	faLock,
	faLockOpen,
	faFilter,
	faFilePdf,
	faKey,
	faTachometerAlt,
	faPauseCircle,
	faPlayCircle,
	faStar,
	faClock,
	faStopCircle,
	faPlane,
	faExclamationCircle,
	faCopy,
	faExclamationTriangle,
	faCheck,
	faSignInAlt,
	faPlus,
	faSquare,
	faArrowCircleLeft,
	faArrowCircleRight,
	faHistory,
	faTimes,
	faArrowCircleUp,
	faCircleNotch,
	faCheckCircle,
	faTimesCircle,
	faFileAlt,
	faTable,
	faBars,
	faSpinner,
	faDownload,
	faEllipsisH,
	faTrash,
	faUser,
	faHome,
	faCog,
	faHeart,
	faThLarge,
	faCompress,
	faExpand,
	faShareAlt,
	faChartBar,
	faClone,
	faUsers,
	faPencilAlt,
	faExternalLinkAlt,
	faTools,
	faListAlt,
	faChartLine,
	faRedo,
	faUndo,
	faFile,
	faDatabase,
	faCalendarAlt,
	faCalendar,
	faArrowUp,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faCircle,
	faQuestionCircle,
	faPlusCircle,
	faMinusCircle,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronCircleUp,
	faChevronCircleDown,
	faToggleOn,
	faToggleOff,
	faStepBackward,
	faStepForward,
	faChevronLeft,
	faChevronRight,
	faSearch,
	faEye,
	faPaperPlane,
	faCloudUploadAlt,
	faUpload,
	faFlag
);

const localStorageKey = 'welcomeToTheMachine';
const persistedState = loadState(localStorageKey, Map());

const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	connectRouter(history)(rootReducer),
	persistedState,
	composeEnhancer(applyMiddleware(routerMiddleware(history), socketMiddleware()))
);
console.log('store', store);
const state = store.getState().toJS();

// On dispatch of events, stamp full state into localStorage
store.subscribe(() => {
	saveState(localStorageKey, store.getState());
});

// connected router: Synchronize router state with redux store
// through uni-directional flow (i.e. history -> store -> router -> components).
const aaiApp = () => {
	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	);
};

if (state.user) {
	console.log(state.user.firstName, 'logged in');
	API.auth(state.user)
		.then((r) => {
			store.dispatch({ type: 'LOGIN', user: r });
			aaiApp();
		})
		.catch((error) => {
			console.log('error', error);
			store.dispatch({ type: 'LOGOUT' }); // TODO
			aaiApp();
		});
} else {
	console.log('no user found in state...');
	aaiApp();
}

serviceWorker.unregister();
