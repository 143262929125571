import React from 'react';
import { connect } from 'react-redux';
import API from '../../../lib/api-store';
import { withRouter } from 'react-router-dom';
import { enterCase, enterProject } from '../../../redux/actions';
import { COLLECTIONS } from '../../../lib/constants';

const ActivityLink = ({ type, item, project, history, enterCaseAction, enterProjectAction }) => (
	<div
		className="activity-link"
		onClick={() => {
			if (type === COLLECTIONS.PROJECT) {
				API.enterProject(item._id).then((r) => {
					enterProjectAction(item);
					history.push(`/project/${item._id}`);
				});
			} else if (type === COLLECTIONS.CASE) {
				API.enterProject(project._id).then((r) => {
					enterProjectAction(project);
					API.enterCase(item._id).then((r) => {
						enterCaseAction(item);
						history.push('/experiment');
					});
				});
			}
		}}
	>
		{item.name}
	</div>
);

const mapDispatchToProps = (dispatch) => ({
	enterCaseAction: (cse) => dispatch(enterCase(cse)),
	enterProjectAction: (project, dataset) => dispatch(enterProject(project, dataset))
});

export default withRouter(connect(null, mapDispatchToProps)(ActivityLink));
